export default function insertEazelScript(src = '', container = document.body) {
  return new Promise((resolve, reject) => {
    if (!container || !(container instanceof HTMLElement)) {
      console.error('Invalid container element')
      return reject(new Error('Invalid container element'))
    }

    const script = document.createElement('script')
    script.onload = () => {
      console.log('Script loaded successfully:', src)
      script.onload = null

      try {
        if (!window.eazelEmbed) {
          window.eazelEmbed = { css: undefined, recvMsg: undefined }
        }

        document.querySelectorAll('iframe[data-eazel-embed-id]').forEach(iframe => {
          window.eazelEmbed[iframe.dataset.eazelEmbedId] = iframe

          // Set a default height for the iframe
          iframe.style.height = '500px' // Set your desired default height here
        })

        let styleElement = document.getElementById('eazel-embed__css')
        if (!styleElement) {
          styleElement = document.createElement('style')
          styleElement.id = 'eazel-embed__css'
          document.head.appendChild(styleElement)
          window.eazelEmbed.css = styleElement
        }

        if (!window.eazelEmbed.recvMsg) {
          window.eazelEmbed.recvMsg = event => {
            if (!/^https:\/\/(.+\.|)eazel\.net$/.test(event.origin) || event.data?.type !== 'embed') return
            const { id, action, value } = event.data

            console.log(value)

            if (action === 'resize') {
              const iframe = window.eazelEmbed[id]
              if (iframe) {
                iframe.style.height = `${value}px`
              }
            }
          }
          window.addEventListener('message', window.eazelEmbed.recvMsg)
        }

        window.addEventListener(
          'unload',
          () => {
            if (!window.eazelEmbed) return
            if (window.eazelEmbed.css) window.eazelEmbed.css.remove()
            if (window.eazelEmbed.recvMsg) window.removeEventListener('message', window.eazelEmbed.recvMsg)
            window.eazelEmbed = undefined
          },
          { once: true }
        )

        resolve()
      } catch (error) {
        console.error('[eazel embed]', error)
        reject(error)
      }
    }

    script.onerror = () => {
      console.error('Failed to load script:', src)
      reject(new Error('Failed to load script'))
    }

    script.setAttribute('defer', '')
    script.src = src
    container.appendChild(script)
  })
}

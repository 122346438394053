import Component from '../../../assets/scripts/modules/component'
import { getCookie } from '../../../assets/scripts/utilities/simple-cookies'

export default class SmartifyEmbedComponent extends Component {
  init() {
    this.iframe = this.element.querySelector('.smartify-embed__iframe')

    if (!this.iframe) {
      return
    }

    this.buttonCookies = this.element.querySelector('.button--cookies')
    this.notice = this.element.querySelector('.smartify-embed__cookies-notice')
    this.caption = this.element.querySelector('.smartify-embed__caption')

    this.buttonCookies?.addEventListener('click', event => {
      event.preventDefault()
      window.dispatchEvent(new CustomEvent('show-cookie-details'))
    })

    window.addEventListener('cookie-consent', () => this.checkCookie())
    this.checkCookie()
  }

  initSmartify() {
    if (!this.iframe.getAttribute('data-src')) {
      return
    }

    this.iframe.setAttribute('src', this.iframe.getAttribute('data-src'))
    this.iframe.removeAttribute('data-src')
  }

  checkCookie() {
    const cookie = getCookie('cookie-consent')

    if (!cookie || cookie.split(',').indexOf('embeds') === -1) {
      this.showCookieNotice()
    } else {
      this.hideCookieNotice()
    }
  }

  showCookieNotice() {
    if (this.notice) {
      this.notice.removeAttribute('hidden')
    }

    if (this.caption) {
      this.caption.setAttribute('hidden', 'hidden')
    }

    this.iframe.setAttribute('hidden', 'hidden')
    this.element.classList.add('smartify-embed--cookies-not-accepted')
  }

  hideCookieNotice() {
    if (this.notice) {
      this.notice.setAttribute('hidden', 'hidden')
    }

    if (this.caption) {
      this.caption.removeAttribute('hidden')
    }

    this.iframe.removeAttribute('hidden')
    this.element.classList.remove('smartify-embed--cookies-not-accepted')

    this.initSmartify()
  }
}

window.addEventListener('init-delayed-load', () =>
  [...document.querySelectorAll('.smartify-embed')].forEach(element => {
    element.instance = element.instance || new SmartifyEmbedComponent(element)
  })
)

import Component from '../../../assets/scripts/modules/component'
import insertEazelScript from '../../../assets/scripts/utilities/insert-eazel-script-async'
import { getCookie } from '../../../assets/scripts/utilities/simple-cookies'

export default class EazelEmbedElementComponent extends Component {
  init() {
    this.iframe = this.element.querySelector('.eazel-embed__iframe')
    this.container = this.element.querySelector('.eazel-embed__container')

    if (!this.iframe) {
      return
    }

    this.buttonCookies = this.element.querySelector('.button--cookies')
    this.notice = this.element.querySelector('.eazel-embed__cookies-notice')
    this.caption = this.element.querySelector('.eazel-embed__caption')

    this.buttonCookies?.addEventListener('click', event => {
      event.preventDefault()
      window.dispatchEvent(new CustomEvent('show-cookie-details'))
    })

    window.addEventListener('cookie-consent', () => this.checkCookie())
    this.checkCookie()
  }

  initeazel() {
    if (!this.iframe.getAttribute('data-src')) {
      console.error('Data-src attribute not found')
      return
    }

    this.iframe.setAttribute('src', this.iframe.getAttribute('data-src'))
    this.iframe.removeAttribute('data-src')
  }

  checkCookie() {
    const cookie = getCookie('cookie-consent')

    if (!cookie || cookie.split(',').indexOf('embeds') === -1) {
      this.showCookieNotice()
    } else {
      this.hideCookieNotice()
    }
  }

  showCookieNotice() {
    if (this.notice) {
      this.notice.removeAttribute('hidden')
    }

    if (this.caption) {
      this.caption.setAttribute('hidden', 'hidden')
    }

    this.iframe.setAttribute('hidden', 'hidden')
    this.element.classList.add('eazel-embed--cookies-not-accepted')
  }

  hideCookieNotice() {
    if (this.notice) {
      this.notice.setAttribute('hidden', 'hidden')
    }

    if (this.caption) {
      this.caption.removeAttribute('hidden')
    }

    this.iframe.removeAttribute('hidden')
    this.element.classList.remove('eazel-embed--cookies-not-accepted')

    this.initeazel()
    this.initExternalScript()
  }

  async initExternalScript() {
    if (!this.container) {
      return
    }

    await insertEazelScript('https://www.eazel.net/embed_parent/v1.js')
  }
}

window.addEventListener('init-delayed-load', () =>
  [...document.querySelectorAll('.eazel-embed')].forEach(element => {
    element.instance = element.instance || new EazelEmbedElementComponent(element)
  })
)

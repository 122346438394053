import Component from '../../../assets/scripts/modules/component'

const SWIPER_OPTIONS = {
  autoPlay: false,
  controlBy: 'container',
  direction: 'horizontal',
  effect: 'slide',
  grabCursor: true,
  initialSlide: 0,
  loop: true,
  loopAdditionalSlides: 4,
  navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
  pagination: false,
  paginationClickable: true,
  preloadImages: false,
  resistanceRatio: 0,
  roundLengths: true,
  slidesPerView: 1,
  spaceBetween: 0,
  threshold: 5,
  touchRatio: 1,
  watchOverflow: true,
  autoHeight: false,
}

let timeout

export default class ObjectSliderCarouselComponent extends Component {
  async init() {
    await this.loadSwiper()

    const imagesContainer = document.querySelector('.swiper-container')
    imagesContainer.addEventListener('mouseover', () => {
      const title = document.querySelector('.pagination-caption__link')
      title.classList.add('hover')
    })
    imagesContainer.addEventListener('mouseout', () => {
      const title = document.querySelector('.pagination-caption__link')
      title.classList.remove('hover')
    })
    
    const caption = document.querySelector('.pagination-caption')
    caption.addEventListener('mouseover', () => {
      // Note that active slide here **is** up to date
      const activeSlide = this.element.querySelector('.swiper-slide-active')
      activeSlide.classList.add('hover')
    })
    caption.addEventListener('mouseout', () => {
      // Note that active slide here **is** up to date
      const activeSlide = this.element.querySelector('.swiper-slide-active')
      activeSlide.classList.remove('hover')
    })
  }

  async loadSwiper() {
    const { Swiper, A11y, Navigation, Pagination } = (await import('../../../assets/scripts/plugins/swiper')).default()

    this.observer = null
    this.container = this.element.querySelector('.swiper-container')

    this.slidesPerView = this.element.getAttribute('data-slides-per-view') || '1'
    this.labelPrevious = this.element.getAttribute('data-label-previous') || ''
    this.labelNext = this.element.getAttribute('data-label-next') || ''

    let swiperOptions = {
      ...SWIPER_OPTIONS,
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
      slidesPerView: this.slidesPerView,
      pagination: {
        el: this.element.querySelector('.swiper-pagination'),
        type: 'custom',
        renderCustom: (swiper, current, total) => {
          const caption = swiper.slides[swiper.activeIndex].querySelector('.object-figure__caption')
          const word = document.documentElement.dir === 'rtl' ? 'من' : this.getPaginationSeparator()
          const anchorElement = this.element.querySelector('a')

          return `
          ${anchorElement ? `<a href="${anchorElement.href}" class="pagination-caption pagination-caption__link">` : '<div class="pagination-caption">'}
            ${caption ? caption.innerHTML : ''}
          ${anchorElement ? `</a>` : '</div>'}
          <div class="pagination-numbers">
            <span class="pagination-number pagination-number--current">${current}</span>
            <span class="pagination-word">${word}</span>
            <span class="pagination-number pagination-number--total">${total}</span>
          </div>
        `
        },
      },
      a11y: { prevSlideMessage: this.labelPrevious, nextSlideMessage: this.labelNext },
    }

    if ([...this.container.querySelectorAll('.swiper-slide')].length < 2) {
      swiperOptions = { ...swiperOptions, ...{ noSwipe: true, loop: false } }

      this.container.querySelectorAll('.swiper-button-prev, .swiper-button-next').forEach(button => button.parentNode.removeChild(button))
    }

    this.swiper = new Swiper(this.container, { ...swiperOptions, modules: [A11y, Navigation, Pagination] })

    window.clearTimeout(timeout)
    timeout = window.setTimeout(() => this.onSwiperSlideChange(), 500)

    // Prevent button hold selected state after click so the standard styling applies.
    const nextEl = swiperOptions.navigation.nextEl
    const prevEl = swiperOptions.navigation.prevEl
    nextEl.addEventListener('click', () => nextEl.blur())
    prevEl.addEventListener('click', () => prevEl.blur())
  }

  onSwiperSlideChange() {
    if (!this.swiper || !this.swiper.slides) {
      return
    }

    const current = this.swiper.slides[this.swiper.activeIndex]
    const all = Object.values(this.swiper.slides).filter(slide => !!slide.tagName)
    const currentWithDuplicates = all.filter(slide => slide.getAttribute('data-swiper-slide-index') === current.getAttribute('data-swiper-slide-index'))

    currentWithDuplicates.forEach(slide => {
      slide.classList.add('swiper-slide--activated')
    })

    // Hacky trigger for duplicated slides -EKL
    this.swiper.slides.forEach(element => {
      const picture = element.querySelector('.picture')

      if (picture && !picture.instance && window.PictureComponent) {
        picture.instance = new window.PictureComponent(element)
      }
    })

    this.swiper.update()
  }

  getPaginationSeparator() {
    if (document.body.classList.contains('subsite--mathaf') || document.body.classList.contains('subsite--firestation')) return '|'
    return '/'
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.object-slider-carousel').forEach(element => {
    element.instance = element.instance || new ObjectSliderCarouselComponent(element)
  })
)

import Component from '../../../assets/scripts/modules/component'

export default class ObjectCardGrid extends Component {
  init() {
    document.querySelectorAll('.object-card-grid__cards--multiple-cards img').forEach(image => {
      image.onload = () => {
        if (image.naturalWidth > image.naturalHeight) {
          image.classList.add('picture__image--is-landscape')
        }
      }
    })
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.object-card-grid').forEach(element => {
    element.instance = element.instance || new ObjectCardGrid(element)
  })
)

import breakpoints from '../../../assets/scripts/modules/breakpoints'
import Component from '../../../assets/scripts/modules/component'

const TWEEN_VALUES = {
  mobile: { startTitleWrapper: 120, endTitleWrapper: -400, startMetadataWrapper: -150, endMetadataWrapper: -300 },
  portrait: { startTitleWrapper: 120, endTitleWrapper: -400, startMetadataWrapper: -150, endMetadataWrapper: -250 },
  landscape: { startTitleWrapper: 80, endTitleWrapper: -400, startMetadataWrapper: -150, endMetadataWrapper: -350 },
  notebook: { startTitleWrapper: 20, endTitleWrapper: -400, startMetadataWrapper: 20, endMetadataWrapper: -200 },
}

const TWEEN_VALUES_PLAYLIST = {
  mobile: { startTitleWrapper: 50, endTitleWrapper: -150, startMetadataWrapper: -120, endMetadataWrapper: -80 },
  portrait: { startTitleWrapper: 80, endTitleWrapper: -100, startMetadataWrapper: -50, endMetadataWrapper: -25 },
  landscape: { startTitleWrapper: 80, endTitleWrapper: -400, startMetadataWrapper: -150, endMetadataWrapper: -350 },
  notebook: { startTitleWrapper: 25, endTitleWrapper: -400, startMetadataWrapper: 60, endMetadataWrapper: 0 },
}

class VideoStoryHeader extends Component {
  init() {
    this.isPlaylistHeader = this.element.dataset.hasPlaylist
    this.ctx = null

    if (!document.querySelector('.subsite--mia') && !document.querySelector('.subsite--321') && !document.querySelector('.subsite--nmoq') && !document.querySelector('.subsite--mathaf') && !document.querySelector('.subsite--m7') && !document.querySelector('.subsite--liwan') && !document.querySelector('.subsite--designdoha') && !document.querySelector('.subsite--tasweer') && !document.querySelector('.subsite--rubaiyat') && !document.querySelector('.subsite--firestation')) {
      window.addEventListener('gsap-loaded', () => this.initScrollTween())
      window.addEventListener('resize', () => window.requestAnimationFrame(() => this.initScrollTween()))
    }
  }

  initScrollTween() {
    // Clean up any existing scroll triggers within the context -RVP
    if (this.ctx) {
      this.ctx.revert()
    }

    const titleWrapper = [...this.element.querySelectorAll('.video-story-header__title-wrapper')]
    const metadataWrapper = [...this.element.querySelectorAll('.video-story-header__metadata-wrapper')]

    if (!titleWrapper || !metadataWrapper) {
      return
    }

    const initValues = { ...(this.isPlaylistHeader ? TWEEN_VALUES_PLAYLIST : TWEEN_VALUES) }
    let values

    if (breakpoints.isNotebook()) {
      values = { ...initValues.notebook }
    } else if (breakpoints.isLandscape()) {
      values = { ...initValues.landscape }
    } else if (breakpoints.isPortrait()) {
      values = { ...initValues.portrait }
    } else {
      values = { ...initValues.mobile }
    }

    const { startTitleWrapper, endTitleWrapper, startMetadataWrapper, endMetadataWrapper } = values

    // (re)new context for the scroll triggers -RVP
    this.ctx = window.GSAP.context(() => {
      window.GSAP.set(titleWrapper, { force3D: true }) // We're gonna be needing the GPU real soon -EKL
      window.GSAP.fromTo(titleWrapper, { y: startTitleWrapper }, { y: endTitleWrapper, scrollTrigger: { trigger: titleWrapper, scrub: 0 }, ease: 'linear' })
      window.GSAP.set(metadataWrapper, { force3D: true }) // We're gonna be needing the GPU real soon -EKL
      window.GSAP.fromTo(metadataWrapper, { y: startMetadataWrapper }, { y: endMetadataWrapper, scrollTrigger: { trigger: metadataWrapper, scrub: 0 }, ease: 'linear' })
    })
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.video-story-header').forEach(element => {
    element.instance = element.instance || new VideoStoryHeader(element)
  })
)
